import { ApplicationEnum } from "containers/referential/referential.typings";

export enum DomainEnum {
  MARKVIEW = "markview",
  SGVIEW = "sgview",
  MASSUPLOAD = "massupload",
  CORRECTION = "correction",
  FORECAST = "forecast",
  WEBMODULES = "webmodules",
  EXTENDEDMARKVIEW = "extendedmarkview",
  EXTENDEDSGVIEW = "extendedsgview",
  FACETVIEW = "facetview",
  ASOFVIEW = "asofview",
  DATAQUALITY = "dataquality",
  DATACOLLECT = "datacollect",
  CLIENTCONTRIBUTION = "clientcontribution",
}

export interface UserRequestDTO {
  userId?: string;
  requestId?: number;
  validatorComment: string;
  email?: string;
  application?: string;
  applicationId?: string;
  lastModificationDate?: string;
  lastRequestStatus?: string;
  lastRequestStatusId?: string;
  endDate?: string;
  validators?: string[];
}

export interface UserRequestDetailDTO {
  requestId?: number;
  userId: string;
  validatorId: string;
  userComment: string;
  validatorComment: string;
  status: string;
  statusId: string;
  application: string;
  applicationId: ApplicationEnum;
  createdDate: string;
  modifiedDate: string;
  modifiedUserId?: string;
  startDate: string;
  endDate?: string;
  claims?: ClaimDTO[];
  isValidateCommentRequired?: boolean;
  impulseId?: string;
  cerberusRole?: string;
  validators?: string[];
  requestorId?: string;
  needKoreanData: boolean | null;
  koreanDataComment: string | null;
}

export interface ClaimDTO {
  claimId: string;
  claim?: string;
  claimValue: string;
  domainId: DomainEnum;
  claimSet: number;
  axisId?: string;
  operator: string;
}

export interface UserRequestCreateDTO {
  userId?: string;
  applicationId: string;
  cerberusRoleId: string;
  userComment: string;
  impulseId?: string;
}

export interface RequestPageDTO {
  requests: UserRequestDTO[];
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface UserRequestExportDTO {
  email?: string;
  contactId?: string;
  firstName?: string;
  lastName?: string;
  startDate: string;
  endDate?: string;
  status: string;
  domain: string;
  visibility: string;
  department?: string;
  country?: string;
  businessLine?: string;
}

export interface UserRequestExportResponse {
  requests: UserRequestExportDTO[];
}

export interface UserRequestsListResponse {
  requests: UserRequestDetailDTO[];
}

export interface KeyValue {
  key: string;
  value: string;
}

export interface UserCoverageResponse {
  keysValues: KeyValue[];
}
